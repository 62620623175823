<template>
    <div>
        <!-- <a :href="src" scrolling="No" frameborder="0"  id="paymentFrame">click me</a> -->
        <!-- <iframe  width="482" height="500" scrolling="No" frameborder="0"  id="paymentFrame" :src="src"></iframe> -->
    </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
export default {
    components: {},
    computed: mapState({
        getCCAvenuePaymentMode: state => {
            return state.payments.ccAvenuePaymentMode
        }
    }),
    methods: {
        ...mapActions({
            setPaymentStatus: "payments/setPaymentStatus"
        }),
    },
    data() {
        return {
            src: ''
        }
    },
    async mounted() {
        await this.setPaymentStatus();
        this.src = `https://${this.getCCAvenuePaymentMode}.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${this.$route.params.merchantId}&encRequest=${this.$route.params.encReq}&access_code=${this.$route.params.accessCode}`
        window.location.href = this.src
    }
}
</script>

<style></style>